<template>
  <div class="flex flex-col space-y-1 text-sm text-gray-500">
    <Listbox :modelValue="modelValue" @update:modelValue="onUpdate" :multiple="true" class="group">
      <div class="relative">
        <InputLabel v-if="label" :id="id || name" :required="required" class="mb-1">
          {{ label }}
        </InputLabel>
        <ListboxButton
          class="relative w-full flex items-center justify-between cursor-pointer rounded p-3 text-xs text-left shadow-sm focus-within:shadow-blue-100 border border-gray-300 focus:outline-none group-focus-within:border-blue-300 group-focus-within:ring-1 group-focus-within:ring-blue-200 group-focus-within:ring-offset-1 group-focus-within:ring-offset-blue-200"
          :disabled="disabled"
          :class="[
            compact ? 'h-9' : 'h-12',
            disabled
              ? 'bg-gray-50 cursor-not-allowed'
              : 'bg-white hover:border-gray-400 cursor-pointer',
            {
              'border-red-300 hover:border-red-400': error,
            },
          ]"
        >
          <span
            class="block truncate text-xs font-medium"
            :class="!staticPlaceholder && (value || selectedOptions.length) ? 'text-gray-500' : 'text-gray-300'"
            >{{ buttonValue }}</span
          >
          <span class="pointer-events-none">
            <LockClosedIcon
              v-if="disabled"
              class="h-5 w-5 text-gray-400 transform duration-300"
              aria-hidden="true"
            />
            <ChevronUpDownIcon
              v-else
              class="h-5 w-5 text-gray-500 transform duration-300"
              aria-hidden="true"
            />
          </span>
        </ListboxButton>

        <Grow>
          <ListboxOptions
            class="absolute top-full mt-1 w-full overflow-auto rounded-lg bg-white p-4 pr-2 text-sm border border-gray-200 focus:outline-none shadow-md z-30"
            :style="{ maxHeight: dropdownHeight }"
          >
            <slot name="beforeOptions"></slot>
            <ListboxOption
              v-slot="{ active, selected }"
              v-for="option in options"
              :key="option.name"
              :value="option"
              as="template"
            >
              <li v-if="option.custom">
                <slot name="option" :option="option" :active="active"></slot>
              </li>
              <li
                v-else
                class="mb-1 cursor-pointer select-none p-3 font-medium flex items-center space-x-3 rounded"
                :class="{
                  'bg-gray-100': active,
                  'text-black': selected,
                  'bg-gray-50': selected && !active,
                }"
              >
                <span
                  class="shrink-0 w-4 h-4 flex items-center justify-center border rounded-sm text-primary-on"
                  :class="selected ? 'bg-primary border-primary' : 'bg-white border-gray-300'"
                >
                  <CheckIcon class="w-3.5 h-3.5" aria-hidden="true" />
                </span>
                <slot name="option" :option="option">
                  <span class="block truncate">{{ option.name }}</span>
                </slot>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </Grow>
      </div>
    </Listbox>
    <ErrorMessage v-if="error">{{ error }}</ErrorMessage>
    <HelpMessage v-if="helpText">{{ helpText }}</HelpMessage>
    <ul v-if="showTags" class="mt-1 flex gap-2">
      <LabelDefault
        v-for="option in selectedOptions"
        :key="option.name"
        :label="option.name"
        :removable="true"
        @remove="removeSelectedOption(option)"
      />
    </ul>
  </div>
</template>

<script setup>
import { computed, ref } from "vue"
import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from "@headlessui/vue"
import { ChevronUpDownIcon, CheckIcon, LockClosedIcon } from "@heroicons/vue/20/solid"
import InputLabel from "./_base/InputLabel.vue"
import LabelDefault from "../labels/LabelDefault.vue"
import { useI18n } from "vue-i18n"
import ErrorMessage from "./_base/ErrorMessage.vue"
import HelpMessage from "./_base/HelpMessage.vue"
import Grow from "@/components/transitions/Grow.vue"

const props = defineProps({
  label: String,
  id: String,
  placeholder: String,
  staticPlaceholder: Boolean, // to always show placeholder value
  error: [Boolean, String],
  helpText: String,
  compact: Boolean,
  disabled: Boolean,
  value: String, // value to show in button
  options: {
    type: Array,
    default: () => [], // [{name: 'option1', custom: true(optional)}]
  },
  modelValue: {
    type: Array,
    default: () => [], // same type as options
  },
  required: {
    type: Boolean,
  },
  dropdownHeight: {
    type: String,
    default: "15rem",
  },
  showTags: Boolean,
})
const emit = defineEmits(["update:modelValue"])

const { t } = useI18n()

const selectedOptions = ref(props.modelValue)
const removeSelectedOption = option => {
  const i = selectedOptions.value.findIndex(item => item.name === option.name)
  if (i > -1) {
    selectedOptions.value.splice(i, 1)
  }
}

const onUpdate = value => {
  selectedOptions.value = value
  emit("update:modelValue", selectedOptions.value)
}

const buttonValue = computed(() => {
  if (props.staticPlaceholder) return props.placeholder
  if (props.value) return props.value
  if ((props.label && !props.placeholder) || selectedOptions.value.length)
    return t("forms.options_selected", { count: selectedOptions.value.length })
  return props.placeholder
})
</script>
